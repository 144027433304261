import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./ChargePosting.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
const ChargePosting = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <div className="banner">
        <Image
          fluid
          src={process.env.PUBLIC_URL + "/images/charge.jpg"}
          alt="Banner"
          width={"100%"}
          height={"100%"}
          className="img-fluid"
        />
      </div>
      {/* <!-- Page Title --> */}
      <section class="section page-title">
        <div class="container">
          <div class="">
            <h1>
              <img
                src="/images/charge.png"
                style={{
                  height: "100px",
                }}
              />
              <br />
              Charge Posting
            </h1>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="left-content">
                    <h2>Medical Billing Charge Posting</h2>
                    <p>
                      What is charge posting or charge entry? It is the process
                      of assigning an appropriate fee based on the fee schedule,
                      per the chosen medical codes during the patient’s visit.
                      The provider’s reimbursement for services rendered is
                      dependent on the charges entered. It starts with the
                      superbill or charge ticket, goes through coding and then
                      goes to the charge entry person who ensures that all
                      information is entered correctly to build the claim. The
                      charge entry must be completed error-free, as it can
                      increase claim denials if not entered and scrubbed
                      correctly, and has to be done promptly so as not to miss
                      the payer-established deadlines for filing claims.
                    </p>
                    <p>
                      As charge posting is the first step in a clean medical
                      billing claim process, information such as demographics,
                      date of service, place of service, provider details,
                      physician, procedure and diagnostic codes need to all be
                      added and correct. Not only is this work tedious but
                      time-consuming as well. Any error can lead to rejection or
                      denial of the claim by the payer, delaying payment and
                      causing a slowdown in the cashflow of the practice. Thus
                      it is important for the team handling charge posting to be
                      exceptionally detail-oriented to ensure that all the
                      information provided in the charge entry is correct. Do
                      you have a backlog of superbills and charges? Not enough
                      resources to help? Partnering with Billed Right for your
                      revenue cycle management can remove the stress of charge
                      entry, ensuring your medical billing claims go out clean
                      and are paid promptly. Lessening denials and increasing
                      revenue.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Title End--> */}
    </>
  );
};

export default ChargePosting;
