import React, { useState } from "react";
import "./Navigation.css";
import { Navbar, Button, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const Navigation = (props) => {
  const [isOpenBilling, setIsOpenBilling] = useState(false);
  const [isOpenMore, setIsOpenMore] = useState(false);
  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="navigation bg-body-tertiary">
        <Navbar.Brand id="brand-logo">
          <Link to="/">
            <img
              src={process.env.PUBLIC_URL + "/icons/mainlogo.jpg"}
              className="DOCLOgo"
              alt="logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown
              title="Billing Services"
              id="basic-nav-dropdown"
              onMouseEnter={() => setIsOpenBilling(true)}
              onMouseLeave={() => setIsOpenBilling(false)}
              show={isOpenBilling}
            >
              <NavDropdown.Item href="/billing-services/ar-follow">
                Ar Follow up
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/billing-services/denial-management">
                Denial management
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/billing-services/medical-coding">
                Medical coding
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/billing-services/credentialing">
                Credentialing
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/billing-services/dental-billing">
                Dental billing
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/billing-services/kareo-billing">
                Kareo Billing
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/billing-services/ecw-billing">
                eCW Billing
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/billing-services/medical-billing">
                Medical Billing
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/career">Career</Nav.Link>
            <Nav.Link href="/ourservices">Services</Nav.Link>
            <Nav.Link href="/contactus">Contact Us</Nav.Link>
            {/* <NavDropdown
              title="More"
              id="basic-nav-dropdown"
              onMouseEnter={() => setIsOpenMore(true)}
              onMouseLeave={() => setIsOpenMore(false)}
              show={isOpenMore}
            >
              <NavDropdown.Item href="/what-we-are">
                What We Are
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/security-complaince">
                Security & Complaince
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/mission-vision">Mission & Vision</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/our-team">Our Team</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          <Link to="/contactus">
            <button type="button" className="btn btn-Enquiry">
              ENQUIRY NOW
            </button>
          </Link>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
export default Navigation;
