import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./EligiBility.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
const EligiBilty = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="banner">
        <Image
          src={process.env.PUBLIC_URL + "/images/eligibility_benefits_verification (2).jpg"}
          alt="Banner"
          width={"100%"}
          height={"100%"}
          className=""
        />
      </div>
      {/* <!-- Page Title --> */}
      <section class="section page-title">
        <div class="container">
          <h1>
            <img
              src="/icons/eligible.jpg"
              style={{
                height: "70px",
              }}
            />
            <br />
            Eligibility And Benefit{" "}
          </h1>
          <div className="full-width-container">
            <div className="left-content">
              {/* Your left side content goes here */}
              <p>
                <span
                  style={{
                    fontWeight: 800,
                  }}
                >
                  Eligibility & Benefits
                </span>{" "}
                verification is one of the top most integral part in the Medical
                Billing process. As per the industry research, most of the
                insurance denials occur due to improper coverage information.
                Insurance companies frequently make policy changes and updates
                in their health plans. If the provider or the billing office is
                not aware of these changes, this may directly impact the
                practice revenue. Therefore, insurance eligibility verification
                of patients becomes inevitable part in the Medical billing
                process. We at Purview Healthcare have built a template for
                verifying and updating the member’s benefits and Eligibility.
              </p>
            </div>
            <div className="right-image">
              {/* Your right side image goes here */}
              <img
                src="/images/eligibility_benefits_verification.jpg"
                alt="Right Side Image"
                style={{
                  height: "300px",
                }}
              />
            </div>
          </div>
          <section class="section ">
            <div class="container">
              <div class="">
                <div class="col-xs-12 pgtxt pgtxt1">
                  <h4>
                    Eligibility & Benefits Verification process at Purview
                    Healthcare
                  </h4>
                  <h5
                    style={{
                      color: "black",
                      width: "80%",
                    }}
                  >
                    We call the payers directly/IVR or verify the eligibility &
                    benefits through authorized online insurance portals and
                    obtain the patient’s eligibility information like
                  </h5>
                  <ul>
                    <li>Member ID number</li>
                    <li>Coverage effective & termination dates</li>
                    <li>Primary & Secondary coverage details</li>
                    <li>Deductible and Co-pay information</li>
                    <li>
                      If policy termed, other insurance coverage details and a
                      detailed list of benefits depending on the patient’s plan
                    </li>
                  </ul>
                  The eligibility & benefits information is directly uploaded in
                  the respective patient account in the billing system. A copy
                  of the report will be e-mailed to the client before the
                  patient visit the doctor office.
                  <br />
                  <p>
                    Our{" "}
                    <span
                      style={{
                        fontWeight: 900,
                      }}
                    >
                      Insurance Eligibility
                    </span>{" "}
                    verification team follow a standard questionnaire while
                    verifying the patient’s eligibility & benefits. This
                    questionnaire has been built in a way to zero out any
                    rejections from the payers and ensure all the claims sent
                    out reaches the payer as a Clean Claim resulting in
                    maximizing the revenue of the practice
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* <!-- Page Title End--> */}
    </>
  );
};

export default EligiBilty;
