import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./PatientStatements.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
const PatientStatements = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <div className="banner">
        
              <Image
                fluid
                src={process.env.PUBLIC_URL + "/images/patient-statements.jpg"}
                alt="Banner"
                width={"100%"}
                height={"40%"}
                className="img-fluid"
              />
            
      </div>
      {/* <!-- Page Title --> */}
      <section class="section page-title">
        <div class="container">
          <div class="">
            <h1>
              <img
                src="/images/credit.png"
                style={{
                  height: "100px",
                }}
              />
              <br />
              Credits / Patient Statements
            </h1>
            <div className="full-width-container">
              <div className="left-content">
                {/* Your left side content goes here */}
                <h2>Why Patient Statement Services are Invaluable.</h2>
                <p>
                  A patient statement is a medical bill. They can be sent to
                  patients by regular mail or electronically. As a healthcare
                  provider, you most likely spend a considerable amount of money
                  on sending out patient statements. You may even have
                  administrative staff whose sole duty is to manage medical
                  billing.
                </p>
                <p>
                  A patient statement service takes care of all these duties for
                  your practice, which means that you can lower your
                  administrative costs, collect medical fees more efficiently,
                  ensure your staff focuses on your patients.{" "}
                </p>
              </div>
              <div className="right-image">
                {/* Your right side image goes here */}
                <img
                  src="/images/Read-Credit-Card-Statement.jpg"
                  alt="Right Side Image"
                  style={{
                    height:'300px'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Title End--> */}
    </>
  );
};

export default PatientStatements;
