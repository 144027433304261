import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const MedicalCoding = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i"
          rel="stylesheet"
        />
        <link href="css/bootstrap.css" rel="stylesheet" type="text/css" />
        <link href="css/animate.css" rel="stylesheet" type="text/css" />
        <link href="css/jquery.fancybox.css" rel="stylesheet" type="text/css" />
        <link href="css/owl.carousel.css" rel="stylesheet" type="text/css" />
        <link
          rel="stylesheet"
          href="css/style.css"
          type="text/css"
          media="screen"
        />

        <meta name="robots" content="max-image-preview:large" />
        <title>Medical Coding Services </title>
      </Helmet>
      <section class="banner banner-inner wow fadeInDown">
        <img src="/images/Medical-Coding.jpg" alt="Medical Coding" />
      </section>
      {/* <!-- Page Title --> */}
      <section class="section page-title">
        <div class="container">
          <div class="">
            <h1>
              <img
                src="/icons/title-icon-mc.png"
                style={{
                  width: "70px",
                }}
              />
              <br />
              Medical Coding
            </h1>
            <div class="col-xs-12 pgtxt">
              <p>
                Accurate and error-free medical coding not only ensures that
                medical claims are reimbursed timely, but also takes care that
                you get paid optimally. With higher self-pay and compliance
                increasing exponentially, there is greater opportunity for risk.
                Medical coding is a key activity that impacts the financial
                health of your practice. With our accurate medical coding, you
                get higher reimbursement and constant flow of income.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Title End--> */}
      <section class="section blue mcs-process-of-coding">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-xs-12 mcs-poc-left">
              <div class="boxstyle1">
                <img src="/icons/hwd-icon_2.png" style={{
                  width: '80px'
                }}/>
                <h2>Medical Coding</h2>
                Complete, accurate, legible &timely documentation
              </div>
            </div>
            <div class="col-sm-6 col-xs-12 mcs-poc-right">
              <ul>
                <li>
                  <div class="icn"></div>
                  <div class="txt">
                    - AAPC credentialed coders <br />
                    <br />
                    - Efforts to reduce RAC audits
                    <br />
                    <br />
                    - Specialty specific versatile coding services
                    <br />
                    <br />
                    - Accurate &stringent quality checks
                    <br />
                    <br />
                    - CPT and ER with E &M medical coding
                    <br />
                    <br />
                    - ICD-10 coding based on AMA and CMS guidelines
                    <br />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="section text-center">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 pgtxt pgtxt1">
              <h4>
                You Get Certified Medical Coding Specialist at Doc RCM Experts
              </h4>
              <p>
                Doc RCM Experts always keep up with industry standard and
                supplies certified medical billing and coding specialist to
                fulfill your requirements.
              </p>
              <br />
              <b>Process of Medical Coding</b>
              : <br />
              <br />
              Retrieve : Documents/super bills electronically scanned at
              client’s office are securely accessed by our knowledge center
              using secure connections (SSL/VPN). All pertinent data is stored
              at a centralized location on high-end redundant servers in a
              database driven application. This helps to keep all the records in
              a structured and easily retrievable interface. <br />
              <br />
              Verify &Categorize : All documents are checked &verified for
              illegibility, quality &missing documents.
              <br />
              <br />
              Assign: Diagnosis, procedure codes and modifiers are assigned
              based on standards (ICD-9 &CPT) &as per description by doctor.{" "}
              <br />
              <br />
              Assure: Thorough audit &checking of completed medical claims is
              done at multiple levels by quality control team and processed
              further for charge entry and payment posting.
              <br />
              <br />
              <b>Key Gains</b>
              :<br />
              <br />
              <li>Complete, accurate, legible &timely documentation.</li>
              <li>
                Identify the service and interpret the diagnostic and procedural
                phrases into coded form.
              </li>
              <li>
                Providers are paid correctly in a timely manner, complying with
                all guidelines.
              </li>
              <li>
                Regular feedback is provided for minimal denial of claims.
              </li>
            </div>
          </div>
        </div>
      </section>
      <section class="section contact-us wow fadeInDown">
        <div class="container">
          <div class="">
            <div class="col-xs-12">
              <div class="textwidget">
                <div class="cnt-icn">
                  <img src="" />
                </div>
                <div class="cnt-txt">
                  <h2>Contact us today</h2>
                  <p>
                    Learn more today about how Doc RCM Experts can be part of
                    your Success
                  </p>
                  <a href="/contactus" class="btn wow slideInRight">
                    REQUEST A QUOTE NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Other Solution End --> */}
      <section class="section as-other-solution">
        <div class="container">
          <div class="">
            <h2>Other Solution</h2>
            <div class="col-xs-12 tms-boxes">
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>MEDICAL BILLING SERVICES </h4>
                  <p>
                    You can rely on the proven combination of our Billing
                    Footsteps Check and expert staff to get 99% of your claims
                    paid
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/medical-billing" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>AR FOLLOW UP SERVICES</h4>
                  <p>
                    Our aggressive follow up on claims ensures that no lost or
                    ignored claims slip through the cracks
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/ar-follow" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>EMR MEDICAL TRANSCRIPTION </h4>
                  <p>
                    EHR integrated transcription is very important for the
                    quality as well as reimbursement
                  </p>
                  <div class="lmore">
                    <a href="#" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Other Solution End --> */}
      <div id="back-top">
        <a href="#home" class="ui-link">
          <i class="fa fa-angle-up"></i>
        </a>
      </div>
    </>
  );
};

export default MedicalCoding;
