import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const ArFollowUp = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>AR Follow Up Services </title>
        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i"
          rel="stylesheet"
        />
        <link href="css/bootstrap.css" rel="stylesheet" type="text/css" />
        <link href="css/animate.css" rel="stylesheet" type="text/css" />
        <link href="css/jquery.fancybox.css" rel="stylesheet" type="text/css" />
        <link href="css/owl.carousel.css" rel="stylesheet" type="text/css" />
        <link
          rel="stylesheet"
          href="css/style.css"
          type="text/css"
          media="screen"
        />

        <meta name="robots" content="max-image-preview:large" />
      </Helmet>
      <section class="banner banner-inner wow fadeInDown">
        <img src="/images/AR-1.jpg" alt="AR Follow Up Services" />
      </section>
      {/* <!-- Page Title --> */}
      <section class="section page-title">
        <div class="container">
          <div class="">
            <h1>
              <img
                src="/icons/hwd-icon_4.png"
                style={{
                  width: "80px",
                }}
              />
              <br />
              AR Follow Up Services
            </h1>
            <div class="col-xs-12 pgtxt">
              <p>
                <li>
                  We all must accept and be ready to face that the healthcare
                  reimbursement system is appalling. Everyone from patients to
                  insurance companies hold their money tightly because of rough
                  economy. Now days we see more denials, underpayments, and lost
                  or ignored claims because of an increasingly complex and often
                  confusing set of rules and process.
                </li>
              </p>

              <p>
                <li>
                  It's becoming more challenging for physicians to get paid for
                  the services they perform. Medical practices face increased
                  pressure to collect more money and cut their operating
                  expenses.
                </li>
              </p>

              <p>
                <li>
                  Unfortunately, most medical practices are not collecting
                  everything they have billed. Only 70% of claims are paid the
                  first time they are submitted, other 30% of claims are denied,
                  lost or ignored. And of those claims, 60% of them are never
                  resubmitted to payers. Also, when services are paid, they
                  aren't necessarily being paid in full according to their payer
                  contracts.
                </li>
              </p>

              <p>
                <li>
                  Usually, receivables are tracked based on 30-day increments -
                  different actions are scheduled depending on whether a
                  receivable is 30 days old, 60 days old, 90 days old, etc. But
                  each payer a billing office deals with operates on its own
                  schedule, so two receivables that are 30 days old could
                  require completely different actions if they're from different
                  payers. Therefore, the 30/60/90 model is no longer applicable.
                </li>
              </p>

              <p>
                <li>
                  Our A/R Follow Up service is designed to collect more money
                  for Your Practice. We start AR follow up process after claims
                  are created and sent to the insurance company. Our aggressive
                  follow on claims ensures that no lost or ignored claims slip
                  through the cracks and that the appropriate action is taken
                  for all of them.
                </li>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Title End--> */}
      <section class="section blue mcs-process-of-coding">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-xs-12 mcs-poc-left">
              <div class="boxstyle1"> 
                <img
                  src="/icons/hwd-icon_2.png"
                  style={{
                    width: "80px",
                  }}
                />
                <h2>Advantages</h2> Let us help you convert your Old AR into
                Cash Opportunity
              </div>
            </div>
            <div class="col-sm-6 col-xs-12 mcs-poc-right">
              <ul>
                <div class="icn"></div>
                <div class="txt">
                  1. Experienced AR calling team <br />
                  <br />
                  2. Will save you time to focus on revenue cycle management
                  <br />
                  <br />
                  3. We track metrics and work analytically towards reducing
                  denied claims
                  <br />
                  <br />
                  4. Higher AR collection rate at incredibly low costs
                  <br />
                  <br />
                  5. Monthly reporting with Statistics
                  <br />
                </div>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="section text-center">
        <div class="container">
          <div class="">
            <div class="col-xs-12 pgtxt pgtxt1">
              <h4>
                Our AR team is proficient with AR follow up and work
                aggressively to know the status of claim and use professional
                tactics to increase AR collection
              </h4>
              <strong>
                <br />
                Few Frequent Reasons for Claim Rejection
              </strong>
              <ul>
                <li>Authorization issues</li>
                <li>Referral issues</li>
                <li>Medical necessity and medical records requests</li>
                <li>Non-participation with insurance network</li>
                <li>Terminated insurance</li>
                <li>Coordination of benefits</li>
                <li>Wrong diagnosis</li>
                <li>Inclusive procedures</li>
                <li>Partial payments</li>
                <li>Out-of-network claim status and deductibles</li>
                <li>EDI rejections</li>
                <li>Letter of protection from attorney cases</li>
                <li>No status and no claim on file</li>
                <li>Workers' compensation</li>
                <li>PIP cases</li>
              </ul>
              We understand major reason of claim rejection so we do aggressive
              AR follow up and try our best to resolve issue so your practice
              gets paid what you deserve.
              <br />
              <br />
              <strong>Pricing:</strong> We work on fixed pricing as well as
              hourly pricing
              <br />
              <br />
              <strong>Fixed Pricing:</strong> We charge percentage of collection
              based on total amount collected
              <br />
              <br />
              <strong>Hourly Pricing:</strong> We only charge $8 per hour to
              perform AR follow up service
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Contact Us --> */}
      <section class="section contact-us wow fadeInDown">
        <div class="container">
          <div class="">
            <div class="col-xs-12">
              <div class="textwidget">
                <div class="cnt-icn">
                  <img src="/images/contactus-icon.png" />
                </div>
                <div class="cnt-txt">
                  <h2>Contact us today</h2>
                  <p>
                    Learn more today about how Doc RCM Experts can be part of
                    your Success
                  </p>{" "}
                  <a href="/contactus" class="btn wow slideInRight">
                    REQUEST A QUOTE NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Other Solution End --> */}
      <section class="section as-other-solution">
        <div class="container">
          <div class="">
            <h2>Other Solution</h2>
            <div class="col-xs-12 tms-boxes">
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>MEDICAL BILLING SERVICES </h4>
                  <p>
                    You can rely on the proven combination of our Billing
                    Footsteps Check and expert staff to get 99% of your claims
                    paid
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/medical-billing" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>DENIAL MANAGEMENT SERVICES</h4>
                  <p>
                    Denial Management is not introduced to just resubmitting a
                    claim, our experts also analyze the reasons for denial
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/denial-management" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>CREDENTIALING SERVICES</h4>
                  <p>
                    We can perform the entire credentialing process or share the
                    responsibilities with you to apply for your in-network
                    status
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/credentialing" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Other Solution End --> */}
      <div id="back-top">
        <a href="#home" class="ui-link">
          <i class="fa fa-angle-up"></i>
        </a>
      </div>

      {/* <!-- Start of StatCounter Code --> */}
    </>
  );
};

export default ArFollowUp;
