import "bootstrap";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/bootstrap/js/dist/popover.js";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "./components/Navigation/Navigation";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import About from "./components/About/About";
import OurService from "./components/OurServices/OurService";
import Career from "./components/Career/Career";
import Contact from "./components/Contact/Contact";
import ArFollowUp from "./components/BillingServices/ArFollowUp";
import Credentialing from "./components/BillingServices/Credentialing";
import DenialManagement from "./components/BillingServices/DenialManagement";
import DentalBilling from "./components/BillingServices/DentalBilling";
import EcwBilling from "./components/BillingServices/EcwBilling";
import KareoBilling from "./components/BillingServices/KareoBilling";
import MedicalBilling from "./components/BillingServices/MedicalBilling";
import MedicalCoding from "./components/BillingServices/MedicalCoding";
import PatientStatements from "./components/PatientStatements/PatientStatements";
import ChargePosting from "./components/ChargePosting/ChargePosting";
import ClearingHouse from "./components/ClearingHouse/ClearingHouse";
import EligiBilityBenefit from "./components/EligiBility&Benefit/EligiBility";
import PaymentPosting from "./components/PaymentPosting/PaymentPosting";
import WhatWeAre from "./components/More/WhatWeAre/WhatWeAre";
import OurTeam from "./components/More/OurTeam/OurTeam";
import SecurityComplaince from "./components/More/SecurityComplaince/SecurityComplaince";
import MissionVision from "./components/More/MissionVision/MissionVision";
import PageNotFound from "./components/PageNotFound";

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <div className="container-fluid px-0" id="page-container">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/aboutus" component={About} />
            <Route path="/ourservices" component={OurService} />
            <Route path="/career" component={Career} />
            <Route path="/contactus" component={Contact} />
            <Route path="/billing-services/ar-follow" component={ArFollowUp} />
            <Route
              path="/billing-services/credentialing"
              component={Credentialing}
            />
            <Route
              path="/billing-services/denial-management"
              component={DenialManagement}
            />
            <Route
              path="/billing-services/dental-billing"
              component={DentalBilling}
            />
            <Route
              path="/billing-services/ecw-billing"
              component={EcwBilling}
            />
            <Route
              path="/billing-services/kareo-billing"
              component={KareoBilling}
            />
            <Route
              path="/billing-services/medical-billing"
              component={MedicalBilling}
            />
            <Route
              path="/billing-services/medical-coding"
              component={MedicalCoding}
            />
            <Route path="/patient-statements" component={PatientStatements} />
            <Route path="/charge-posting" component={ChargePosting} />
            <Route path="/clearing-house" component={ClearingHouse} />
            <Route path="/eligiBility-benefit" component={EligiBilityBenefit} />
            <Route path="/payment-posting" component={PaymentPosting} />
            <Route path="/what-we-are" component={WhatWeAre} />
            <Route path="/our-team" component={OurTeam} />
            <Route path="/security-complaince" component={SecurityComplaince} />
            <Route path="/mission-vision" component={MissionVision} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </div>
        <Footer id="footer" />
      </Router>
    </div>
  );
}

export default App;
