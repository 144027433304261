import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const EcwBilling = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i"
          rel="stylesheet"
        />
        <link href="css/bootstrap.css" rel="stylesheet" type="text/css" />
        <link href="css/animate.css" rel="stylesheet" type="text/css" />
        <link href="css/jquery.fancybox.css" rel="stylesheet" type="text/css" />
        <link href="css/owl.carousel.css" rel="stylesheet" type="text/css" />
        <link
          rel="stylesheet"
          href="css/style.css"
          type="text/css"
          media="screen"
        />

        <meta name="robots" content="max-image-preview:large" />
        <title>eCW Billing Services </title>
      </Helmet>
      <section class="banner banner-inner wow fadeInDown">
        <img src="/images/ECW-1.jpg" alt="eCW Billing" />
      </section>
      {/* <!-- Page Title --> */}
      <section class="section page-title">
        <div class="container">
          <div class="">
            <h1>
              <img
                src="/icons/title-icon-mu.webp"
                style={{
                  width: "80px",
                }}
              />
              <br />
              eCW Billing
            </h1>
            <div class="col-xs-12 pgtxt">
              <p>
                We at Doc RCM Experts have helped above 500 physicians revise
                profit benchmarks and create better tomorrow for their
                practices. We receive provider’s information, perform
                Eligibility check, Verify Demographic details, Assign Accurate
                codes, do Charge Entry, so from Demographic Entry to Payment
                Posting, everything is entered into the eCW EMR by Doc RCM
                Experts, so doctor has instant access to the information of his
                practice.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Title End--> */}
      <section class="section as-acc-services nopadding">
        <div
          class="col-sm-6 col-xs-12 as-as-left"
          style={{
            backgroundImage: "url(/images/ecw_V10.png)",
          }}
        ></div>
        <div class="col-sm-6 col-xs-12 as-as-right kb-kb-right">
          <h3>We at Doc RCM Experts can work on any Platform!</h3>
          <p>
            We have worked extensively with the eCW Billing Software and have
            produced results that have made our clients happy. eCW has some of
            the most intuitive and comprehensive features around, and a software
            that according to our senior biller, Haze, “feels like home”
          </p>
        </div>
      </section>
      <section class="section ecw-questions">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-xs-12 ecw-box">
              <h3>
                <span style={{ color: "#ee2f30" }}>
                  Clean claims that get paid the first time, every time!
                </span>
              </h3>
              <p>
                The medical coding and charge entry feature offered by the eCW
                billing software helps streamline our billing process further
              </p>
              <p>
                We perform real time code checks against insurer specifics and
                coding guidelines before submitting claims, which leads to
                creaky clean claims. The practices see a rapid reduction in
                rejected claims. And from a rejection rate of an alarming 40%
                physician's experience, it was now down to almost nil!
              </p>
            </div>
            <div class="col-sm-6 col-xs-12 ecw-box">
              <h3>
                <span style={{ color: "#ee2f30" }}>
                  What our New Physician had to say:
                </span>
              </h3>
              <p>
                When the Billing Team of Doc RCM Experts told us that his team
                was well equipped to work on eCW Platform, we were thrilled to
                bits. Doc RCM Experts and eCW Billing Software have helped over
                100 Medical Practices run smoothly without any flaw. Practices
                feel relieved when we managed to work on the eCW Billing
                Software without any loss of data and revenue.
              </p>
              <p>
                Not just that, when Doc RCM Experts, guided us with all the
                features of the software, we were amazed. It took us a week to
                touch base with all the features, but very soon we could see the
                results in the services and the revenue.
              </p>
            </div>
            <div class="col-sm-6 col-xs-12 ecw-box">
              <h3>
                <span style={{ color: "#ee2f30" }}>
                  Eligibility Check - Faster!{" "}
                </span>
              </h3>
              <p>
                Most of the practices are under staffed and cannot perform
                prompt eligibility checks. This leads to patients with
                ineligible insurance coverage to avail of treatment. We use the
                eCW insurance eligibility feature to send eligibility enquiry
                requests to insurers prior to the doctor meeting patients. It
                helps curb the unhealthy trend of insufficient eligibility
                verification.
              </p>
            </div>
            <div class="col-sm-6 col-xs-12 ecw-box">
              <h3>
                <span style={{ color: "#ee2f30" }}>Patient’s Feedback: </span>
              </h3>
              <p>
                Unfriendly or complicated patient statements are the bane of all
                practices. We at Doc RCM Experts generate patient friendly, no
                frills attached patient statements. We prioritize and customize
                patient statement batches based on balance to be paid and the
                frequency of sending patient statements.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Contact Us --> */}
      <section class="section contact-us wow fadeInDown">
        <div class="container">
          <div class="">
            <div class="col-xs-12">
              <div class="textwidget">
                <div class="cnt-icn">
                  <img src="/images/contactus-icon.png" />
                </div>
                <div class="cnt-txt">
                  <h2>Contact us today</h2>
                  <p>
                    Learn more today about how Doc RCM Experts can be part of
                    your Success
                  </p>
                  <a href="/contactus" class="btn wow slideInRight">
                    REQUEST A QUOTE NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Other Solution End --> */}
      <section class="section as-other-solution">
        <div class="container">
          <div class="">
            <h2>Other Solution</h2>
            <div class="col-xs-12 tms-boxes">
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>MEDICAL BILLING SERVICES</h4>
                  <p>
                    You can rely on the proven combination of our Billing
                    Footsteps Check and expert staff to get 99% of your claims
                    paid
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/medical-billing" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>DENIAL MANAGEMENT SERVICES</h4>
                  <p>
                    Denial Management is not introduced to just resubmitting a
                    claim, our experts also analyze the reasons for denial
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/denial-management" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>AR FOLLOW UP SERVICES</h4>
                  <p>
                    Our aggressive follow up on claims ensures that no lost or
                    ignored claims slip through the cracks
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/ar-follow" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Other Solution End --> */}

      <div id="back-top">
        <a href="#home" class="ui-link">
          <i class="fa fa-angle-up"></i>
        </a>
      </div>
    </>
  );
};

export default EcwBilling;
