import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./PaymentPosting.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
const PaymentPosting = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <div className="banner">
        <Image
          fluid
          src={process.env.PUBLIC_URL + "/images/payment-b.png"}
          alt="Banner"
          width={"100%"}
          height={"100%"}
          className="img-fluid"
        />
      </div>
      {/* <!-- Page Title --> */}
      <section class="section page-title">
        <div class="container">
          <h1>
            <img
              src="/images/payment.png"
              style={{
                height: "100px",
              }}
            />
            <br />
            PAYMENT POSTING SERVICES
          </h1>
          <div className="full-width-container">
            <div className="left-content">
              {/* Your left side content goes here */}
              <p>
                One of the important steps of the medical billing process -
                payment posting, is imperative for an optimized revenue cycle.
                Once payments are posted to patient accounts, any denials can be
                addressed following accurate payment posting methods. At the
                same time, quick turnaround time and attention to detail are
                important aspects of the payment posting service and cannot be
                performed easily by just anyone
              </p>
              <p>
                Outsourcing{" "}
                <span
                  style={{
                    fontWeight: 700,
                  }}
                >
                  Payment Posting
                </span>{" "}
                to Purview Healthcare, a leading healthcare services company
                will streamline this process for your medical practice and
                provide you with cost-efficiencies as well. We follow best
                practices in quality management and information security to
                ensure the outsourced payment posting in{" "}
                <span
                  style={{
                    fontWeight: 700,
                  }}
                >
                  Medical Billing Process
                </span>{" "}
                is completed as per your business requirements while upholding
                industry standards.
              </p>
            </div>
            <div className="right-image">
              {/* Your right side image goes here */}
              <img
                src="/images/payment_posting.jpg"
                alt="Right Side Image"
                style={{
                  height: "300px",
                }}
              />
            </div>
          </div>
          <section class="section ">
            <div class="container">
              <div class="">
                <div class="col-xs-12 pgtxt pgtxt1">
                  <h4>
                    Why Choose Purview Healthcare for Payment Posting Services?
                  </h4>
                  <h5
                    style={{
                      color: "black",
                      width: "80%",
                    }}
                  >
                    Our highly experienced team has been providing payment
                    posting services to clients around the world. Some of the
                    key reasons why you need to choose Purview Healthcare
                    include:
                  </h5>
                  <ul>
                    <li>
                      We post claims accurately that highlights overall billing
                      efficiency and analyzes reasons for low inflows
                    </li>
                    <li>
                      Depending on the rate and nature of denials we implement
                      our denial management expertise to sort the problem and
                      bring down the rate of denials
                    </li>
                    <li>
                      We have the domain expertise as well as the experience to
                      maintain accuracy of information as per our clients
                      requirements
                    </li>
                    <li>
                      We are capable of reading and analyzing EOBs easily within
                      a short time as compared to other billers
                    </li>
                    <li>
                      We use EOBs to understand why a patient needs to pay the
                      balance in cases like uncovered insurance, deductibles and
                      co-insurance, etc.
                    </li>
                    <li>
                      We provide Cost-effective payment posting in medical
                      billing process within a quick turn-around time
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* <!-- Page Title End--> */}
    </>
  );
};

export default PaymentPosting;
