import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
// import "./EligiBilty.css";
const ClearHouse = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <div className="banner">
        <Image
          fluid
          src={process.env.PUBLIC_URL + "/images/clearing.png"}
          alt="Banner"
          width={"100%"}
          height={"100%"}
          className="img-fluid"
        />
      </div>
      {/* <!-- Page Title --> */}
      <section class="section page-title">
        <div class="container">
          <h1>
            <img
              src="/images/house.png"
              style={{
                height: "100px",
              }}
            />
            <br />
            Clearing house & Payer Rejection{" "}
          </h1>
          <div className="full-width-container">
            <div className="left-content">
              {/* Your left side content goes here */}
              <p>
                The medical billing software on your desktop creates an
                electronic file (the claim) also known as the ANSI-X12 - 837
                file, which is then uploaded (sent) to your medical billing
                clearinghouse account. The clearinghouse then scrubs the claim
                checking it for errors (arguably the most important thing a
                clearinghouse does); and then once the claim passes inspection,
                the clearinghouse securely transmits the electronic claim to the
                specified payer with which it has already established a secure
                connection that meets the strict standards laid down by a HIPAA.
              </p>
              <p>
                At this stage, the claim is either accepted or rejected by the
                payer, but either way, a status message is usually sent back to
                the clearing house who then updates that particular claim’s
                status in your control panel. Now you have an accepted or
                rejected claim. If rejected, you have a chance to make any
                needed corrections and then re-submit the claim.
              </p>
              <p>
                A rejected claim has been rejected because of errors. An
                insurance company might reject a claim because a medical billing
                specialist incorrectly input patient or insurance information.
                Once a medical billing specialist amends the errors on a
                rejected claim they can resubmit it for processing with an
                insurance company.
              </p>
            </div>
            <div className="right-image">
              {/* Your right side image goes here */}
              <img
                src="/images/payer_rejections.jpg"
                alt="Right Side Image"
                style={{
                  height: "500px",
                }}
              />
            </div>
          </div>
          <section class="section ">
            <div class="container">
              <div class="">
                <div class="col-xs-12 pgtxt pgtxt1">
                  <h3>
                    Reasons to Choose Purview Healthcare for Clearinghouse &
                    Payer Rejection Accurate Resolution For Rejection
                  </h3>
                  <p
                    style={{
                      color: "black",
                      width: "80%",
                    }}
                  >
                    Our team at Purview Healthcare has professionals who pay
                    great attention to detail, which ensures that the rejection
                    resolution provided are highly accurate and error freeOur
                    team at Purview Healthcare has professionals who pay great
                    attention to detail, which ensures that the rejection
                    resolution provided are highly accurate and error free
                  </p>
                </div>{" "}
                <div class="col-xs-12 pgtxt pgtxt1">
                  <h3>Quick Turnaround Time</h3>
                  <p
                    style={{
                      color: "black",
                      width: "80%",
                    }}
                  >
                    We make sure that we deliver all the services within a quick
                    turnaround time to prevent any delay in rejection
                    resolution.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* <!-- Page Title End--> */}
    </>
  );
};

export default ClearHouse;
