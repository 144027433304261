import React from "react";
import "./PageNotFound.css";
const PageNotFound = () => {
  return (
    <section className="page_404">
      <div className="container">
        <div className="col-12 ">
          <div className="col-12 col-sm-offset-1  text-center">
            <div className="four_zero_four_bg">
              <h1 className="text-center ">404</h1>
            </div>

            <div className="contant_box_404">
              <h3 className="h2">Look like you're lost</h3>

              <p
                className=" text-center"
                style={{
                  fontSize: 20,
                  textTransform: "capitalize",
                }}
              >
                the page you are looking for not avaible!
              </p>

              <a
                href="/"
                className="link_404 text-decoration-none"
                style={{
                  backgroundColor: "#ee2f30",
                }}
              >
                Go to Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
