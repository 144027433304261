import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
const DentalBilling = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i"
          rel="stylesheet"
        />
        <link href="css/bootstrap.css" rel="stylesheet" type="text/css" />
        <link href="css/animate.css" rel="stylesheet" type="text/css" />
        <link href="css/jquery.fancybox.css" rel="stylesheet" type="text/css" />
        <link href="css/owl.carousel.css" rel="stylesheet" type="text/css" />
        <link
          rel="stylesheet"
          href="css/style.css"
          type="text/css"
          media="screen"
        />

        <meta name="robots" content="max-image-preview:large" />
        <title>Dental Billing Services </title>
      </Helmet>
      <section class="banner banner-inner wow fadeInDown">
        <img
          src="/images/computer-mouse-tooth.jpeg"
          alt="Dental Billing Services"
        />
      </section>
      {/* <!-- Page Title --> */}
      <section class="section page-title">
        <div class="container">
          <div class="">
            <h1>
              <img
                src="/icons/hwd-icon_4.png"
                style={{
                  width: "70px",
                }}
              />
              <br />
              Dental Billing Services
            </h1>
            <div class="col-xs-12 pgtxt">
              <p
                style={{
                  fontSize: "18px",
                }}
              >
                Dental claims are completely different from Medical claims and
                require proper pre authorization, detailed insurance
                verification and claim coding to ensure maximum reimbursement.
                Investing in Software and electronic billing set up is a lot of
                work for a few claims a month. The general dental office with a
                busy team does not have the time and resources available to
                effectively get maximum payment for services. Most of dental
                practices prefer to outsource their billing needs to dental
                billing companies like ours.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Title End--> */}
      <section class="section blue mcs-process-of-coding">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-xs-12 mcs-poc-left">
              <div class="boxstyle1">
                <img
                  src="/icons/hwd-icon_2.png"
                  style={{
                    width: "70px",
                  }}
                />
                <h2>Advantages </h2>
                Why You Should Use Our Dental Billing and AR follow up Services
              </div>
            </div>
            <div class="col-sm-6 col-xs-12 mcs-poc-right">
              <ul>
                <div class="icn"></div>
                <div
                  class="txt"
                  style={{
                    fontSize: "18px",
                  }}
                >
                  1. Professional Dental Billing Team
                  <br />
                  <br />
                  2. Experienced AR calling team <br />
                  <br />
                  3. We track metrics and work analytically towards reducing
                  denied claims
                  <br />
                  <br />
                  4. Higher AR collection rate at incredibly low costs
                  <br />
                  <br />
                  5. Monthly reporting with Statistics
                  <br />
                </div>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="section text-center">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 pgtxt pgtxt1" style={{
                  fontSize: "18px",
                }}>
              <p>
                Our dental billing specialists are experts of the entire gamut
                of dental procedures. Complying with the American Dental
                Association’s (ADA), list of dental procedures that can be
                billed, our billers scrupulously undertake billing for the
                following service categories
              </p>
              <ul>
                <li>
                  Diagnostic services, preventive, and restorative dental
                  procedures
                </li>
                <li>
                  Maxillofacial prosthetics, oral and maxillofacial surgery, and
                  adjunctive general services
                </li>
                <li>
                  Prosthodontics (removable), prosthodontics (fixed),
                  endodontics, orthodontics, and implant services
                </li>
              </ul>
              With the sole objective of achieving maximum end-to-end dental
              claim reimbursement, our teams of billing specialists have
              developed unique billing process for dental billing.
              <br />
              <br />
              Your payments will be posted accurately to patient ledgers;
              deposits will be matched with EFTs, ERAs, and EOBs. Our extensive
              dental billing experience will help you to increase your practice
              collection
              <br />
              <br />
              BE MORE PRODUCTIVE &PROFITABLE WHILE AVOIDING HEADACHES AND
              LONG-TERM CONTRACTS. WHAT ARE YOU WAITING FOR?{" "}
              <a herf="/contactus">START TODAY!</a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Contact Us --> */}
      <section class="section contact-us wow fadeInDown">
        <div class="container">
          <div class="">
            <div class="col-xs-12">
              <div class="textwidget">
                <div class="cnt-icn">
                  <img src="/images/contactus-icon.png" />
                </div>
                <div class="cnt-txt">
                  <h2>Contact us today</h2>
                  <p>
                    Learn more today about how Doc RCM Experts can be part of
                    your Success
                  </p>
                  <a href="/contactus" class="btn wow slideInRight">
                    REQUEST A QUOTE NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Other Solution End --> */}
      <section class="section as-other-solution">
        <div class="container">
          <div class="">
            <h2>Other Solution</h2>
            <div class="col-xs-12 tms-boxes">
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>MEDICAL BILLING SERVICES </h4>
                  <p>
                    You can rely on the proven combination of our Billing
                    Footsteps Check and expert staff to get 99% of your claims
                    paid
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/medical-billing" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>DENIAL MANAGEMENT SERVICES</h4>
                  <p>
                    Denial Management is not introduced to just resubmitting a
                    claim, our experts also analyze the reasons for denial
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/denial-management" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>CREDENTIALING SERVICES</h4>
                  <p>
                    We can perform the entire credentialing process or share the
                    responsibilities with you to apply for your in-network
                    status
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/credentialing" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Other Solution End --> */}
      <div id="back-top">
        <a href="#home" class="ui-link">
          <i class="fa fa-angle-up"></i>
        </a>
      </div>
    </>
  );
};

export default DentalBilling;
