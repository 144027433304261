import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const MedicalBilling = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Medical Billing Services </title>
        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i"
          rel="stylesheet"
        />
        <link href="css/bootstrap.css" rel="stylesheet" type="text/css" />
        <link href="css/animate.css" rel="stylesheet" type="text/css" />
        <link href="css/jquery.fancybox.css" rel="stylesheet" type="text/css" />
        <link href="css/owl.carousel.css" rel="stylesheet" type="text/css" />
        <link
          rel="stylesheet"
          href="css/style.css"
          type="text/css"
          media="screen"
        />

        <meta name="robots" content="max-image-preview:large" />
      </Helmet>
      <section class="banner banner-inner wow fadeInDown">
        <img src="/images/medical-billing.webp" alt="AR Follow Up Services" />
      </section>
      {/* <!-- Page Title --> */}
      <section class="section page-title">
        <div class="container">
          <div class="">
            <h1>
              <img
                src="/icons/hwd-icon_4.png"
                style={{
                  width: "80px",
                }}
              />
              <br />
              Medical Billing Services
            </h1>
            <div class="col-xs-12 pgtxt">
              <p>
                We understand that the revenue cycle management for medical
                practices has become more complicated than ever. With
                ever-changing insurance company policies, government policies,
                compliance regulations and healthcare reforms, it has become
                tough for physician billing service providers and medical
                billing agencies to keep pace. This results in incorrect or
                delayed filing of claims and poor reimbursements.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Title End--> */}
      <section class="section blue mcs-process-of-coding">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-xs-12 mcs-poc-left">
              <div class="boxstyle1">
                <img
                  src="/icons/hwd-icon_2.png"
                  style={{
                    width: "80px",
                  }}
                />
                <h2>Specialties We Serve:</h2>
              </div>
            </div>
            <div class="col-sm-6 col-xs-12 mcs-poc-right">
              <ul>
                <div class="icn"></div>
                <div class="txt">
                  1. Chiropractic <br />
                  <br />
                  2. Lab Billing
                  <br />
                  <br />
                  3. Cardiology
                  <br />
                  <br />
                  4. Internal Medicine
                  <br />
                  <br />
                  5. Infectious Disease
                  <br />
                </div>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="section text-center">
        <div class="container">
          <div class="">
            <div class="col-xs-12 pgtxt pgtxt1">
              <h3>
                Our process is defined in such a way that we do not miss out on
                any step so no money is left uncollected. experience.
              </h3>
              <ul>
                <h3>Proven Process:</h3>
                <p>
                  As medical billing specialists, we rely on the proven
                  combination of our billing footsteps check and expert staff to
                  get 99% of your claims paid. We make your medical billing &
                  coding process easier. We're dedicated to help you optimize
                  your medical billing collections so you get paid what you
                  deserve.
                </p>{" "}
                <h3>Focus On Patient Care:</h3>
                <p>
                  Our medical claims and billing specialist staff takes on the
                  most time-consuming administrative work, freeing you to focus
                  on your plans for growth and on improving the quality of
                  patient care
                </p>{" "}
                <h3>Full Control Over Your Data:</h3>
                <p>
                  We are one of the leading medical billing agencies with
                  accurate insurance claims processing that gives you full
                  control of your data. We provide web based real time access;
                  With an internet connection you can access your data from
                  anywhere, anytime and in any computer by 24 hours and 7 days
                  in week.
                </p>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Contact Us --> */}
      <section class="section contact-us wow fadeInDown">
        <div class="container">
          <div class="">
            <div class="col-xs-12">
              <div class="textwidget">
                <div class="cnt-icn">
                  <img src="/images/contactus-icon.png" />
                </div>
                <div class="cnt-txt">
                  <h2>Contact us today</h2>
                  <p>
                    Learn more today about how Doc RCM Experts can be part of
                    your Success
                  </p>{" "}
                  <a href="/contactus" class="btn wow slideInRight">
                    REQUEST A QUOTE NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Other Solution End --> */}
      <section class="section as-other-solution">
        <div class="container">
          <div class="">
            <h2>Other Solution</h2>
            <div class="col-xs-12 tms-boxes">
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>MEDICAL BILLING SERVICES </h4>
                  <p>
                    You can rely on the proven combination of our Billing
                    Footsteps Check and expert staff to get 99% of your claims
                    paid
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/medical-billing" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>DENIAL MANAGEMENT SERVICES</h4>
                  <p>
                    Denial Management is not introduced to just resubmitting a
                    claim, our experts also analyze the reasons for denial
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/denial-management" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>CREDENTIALING SERVICES</h4>
                  <p>
                    We can perform the entire credentialing process or share the
                    responsibilities with you to apply for your in-network
                    status
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/credentialing" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Other Solution End --> */}
      <div id="back-top">
        <a href="#home" class="ui-link">
          <i class="fa fa-angle-up"></i>
        </a>
      </div>

      {/* <!-- Start of StatCounter Code --> */}
    </>
  );
};

export default MedicalBilling;
