import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const DenialManagement = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i"
          rel="stylesheet"
        />
        <link href="css/bootstrap.css" rel="stylesheet" type="text/css" />
        <link href="css/animate.css" rel="stylesheet" type="text/css" />
        <link href="css/jquery.fancybox.css" rel="stylesheet" type="text/css" />
        <link href="css/owl.carousel.css" rel="stylesheet" type="text/css" />
        <link
          rel="stylesheet"
          href="css/style.css"
          type="text/css"
          media="screen"
        />

        <meta name="robots" content="max-image-preview:large" />
        <title>Denial Management Services </title>
      </Helmet>
      <div className="banner">
        <Image
          fluid
          src={process.env.PUBLIC_URL + "/images/Denial-Management-1.jpg"}
          alt="Banner"
          width={"100%"}
          height={"100%"}
          className="img-fluid"
        />
      </div>
      {/* <!-- Page Title --> */}
      <section class="section page-title">
        <div class="container">
          <div class="">
            <h1>
              <img
                src="/icons/icon-adv_7.png"
                style={{
                  width: "80px",
                }}
              />
              <br />
              Denial Management
            </h1>
            <div class="col-xs-12 pgtxt">
              <p></p>
              <li>
                Denial rates vary widely depending on physician specialty.
                Denials for some specialties, such as obstetrics and gynecology,
                can be as high as 20 percent. Denials for primary care practices
                can be 10 percent or below. As a rule of thumb, a denial rate of
                15 percent affects practice profitability.{" "}
              </li>
              <p></p>

              <p></p>
              <li>
                Obtaining an accurate reflection of denials for a multispecialty
                practice can be challenging. “The denial rate for a
                multispecialty group practice may be 5 percent, but that overall
                rate may include a 20 percent denial rate for obstetrics and a
                rate of 1 percent for family practice. You need to look at
                denial rates by type of specialty. We segregate denial rates for
                primary care [family practice and internal medicine] from those
                for specialties that have potentially higher denial rates.
              </li>
              <p></p>

              <p></p>
              <li>
                When the denial rate is high, it should be looked at
                immediately. We address two areas of denials: why they are
                occurring and preventing them in the first place.
              </li>
              <p></p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Title End--> */}

      <section class="section as-acc-services nopadding">
        <div
          class="col-sm-6 col-xs-12 as-as-left"
          style={{
            backgroundImage: "url(/images/denial-management.jpg)",
          }}
        ></div>
        <div class="col-sm-6 col-xs-12 as-as-right">
          <p>
            At Doc RCM Experts Denial Management is not introduced to just
            resubmitting a claim. We have a team of experts who analyze the
            reason for denial, track the most common denominators and
            systematically work on identifying and eliminating weak links. Our
            collections and denial management support helped practices reduce
            claims rejection drastically
          </p>
        </div>
      </section>

      <section class="section as-all-services">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-xs-12 hcp-box">
              {/* <img src="/icons/icon-adv_7.png" /> */}
              <h4>
                Information verification before the bill goes out the door:
              </h4>
              <p></p>
              <li>
                The most common reason for denials is incorrect information: ID
                numbers are incorrect, CPT codes or modifiers are not put in the
                right place, names are spelled incorrectly, or names don’t match
                what is printed on the patient’s insurance card.{" "}
              </li>
              <br />
              <li>
                We make sure to verify all these details before claims are sent
                out.
              </li>
              <p></p>
            </div>
            <div class="col-sm-6 col-xs-12 hcp-box">
              {/* <img src="/icons/icon-adv_14.png" /> */}
              <h4>Focus on reasons of denials:</h4>

              <li>
                We understand the regulations around billing to make sure it is
                being done correctly in the first place. The abundance of
                managed care plans, stricter Medicare and Medicaid regulations,
                and the need for frequent- authorizations for payment can
                increase denial rates if not proactively managed.
              </li>
              <br />
              <li>
                {" "}
                Identifying denials associated with billing processes and
                drilling into the reasons allows clinics to error-proof
                processes, decreasing future denials.
              </li>
            </div>
            <div class="col-sm-6 col-xs-12 hcp-box">
              <img src="" />
              <h4>Prompt follow up on denied claim:</h4>
              <p></p>
              <li>
                Depending on the denial reason, we resubmit claims way before
                you even get the paper denial through the mail. By calling the
                insurance company and finding out the denial reason instead of
                waiting to receive the denial in the mail, we correct the reason
                the claim was denied.{" "}
              </li>
              <br />
              <li>
                Resubmitting the claim few days earlier than waiting for the
                denial in the mail will definitely shorten the turnaround time
                for your payment. The bottom line is getting a head start on
                your denials to get the healthcare claims process moving again.{" "}
              </li>
            </div>
            <div class="col-sm-6 col-xs-12 hcp-box">
              <img src="" />
              <h4>We don't assume the insurance company is right:</h4>

              <li>
                We monitor unclaimed revenue reports and appeal denials when
                warranted. The reality is the insurance company may not be
                correct issuing denials, the insurer’s systems may not be up to
                date. If we believe your claim is incorrect based on your
                agreement, then we appeal the claim.
              </li>

              <li>
                Our team also reverts back to the practice with changes in the
                health insurance billing guidelines – as in, revising billed
                amounts – to achieve maximum value on contracted payments.
              </li>
            </div>
            <div class="col-sm-6 col-xs-12 hcp-box">
              <img src="" />
              <h4>Internal Process:</h4>
              <li>
                Building rapport with the insurance representatives in an AR
                Follow-up, while calling the insurance company is important.
                This would help us find solutions for cases where the claims
                have been denied consistently for various reasons including
                global issues. In some instances, the representatives might even
                turn hostile and might not even reveal much of the required
                information, which could prove vital in proceeding further on
                the claims and we have to be very careful in handling situations
                like this.{" "}
              </li>
              <br />
              <li>
                Our AR representatives have strong interpersonal &amp;
                communication skills and are able to make the insurance
                representatives feel comfortable and make the call easy going.{" "}
              </li>
            </div>
            <div class="col-sm-6 col-xs-12 hcp-box">
              <img src="" />
              <h4>When do we call patients?</h4>
              <ul>
                <li>
                  When there is no insurance coverage information found in the
                  demographics section of the patient's account.
                </li>
                <li>
                  When the insurance company has denied a claim stating that the
                  patient is not eligible for coverage at the time of service,
                  where the date of service could be prior to the effective date
                  or after the termination date of patient's insurance coverage.
                </li>
                <li>
                  When any personal info like patient's name, social security
                  number, date of birth, address etc is found to be incorrect in
                  the patient's account
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Contact Us --> */}
      <section
        class="section contact-us wow fadeInDown"
        // style={{ visibility: "hidden", animationName: "none" }}
      >
        <div class="container">
          <div class="">
            <div class="col-xs-12">
              <div class="textwidget">
                <div class="cnt-icn">
                  <img src="/images/contactus-icon.png" />
                </div>
                <div class="cnt-txt">
                  <h2>Contact us today</h2>
                  <p>
                    Learn more today about how Doc RCM Experts can be part of
                    your Success
                  </p>{" "}
                  <a
                    href="/contactus"
                    class="btn wow slideInRight"
                    // style={{ visibility: "hidden", animationName: "none" }}
                  >
                    REQUEST A QUOTE NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Other Solution End --> */}
      <section class="section as-other-solution">
        <div class="container">
          <div class="">
            <h2>Other Solution</h2>
            <div class="col-xs-12 tms-boxes">
              <div
                class="item col-sm-4 col-xs-12 wow fadeInDown"
                // style={{ visibility: "hidden", animationName: "none" }}
              >
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>Medical Billing Services</h4>
                  <p>
                    You can rely on the proven combination of our Billing
                    Footsteps Check and expert staff to get 99% of your claims
                    paid
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/medical-billing" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="item col-sm-4 col-xs-12 wow fadeInDown"
                // style={{ visibility: "hidden", animationName: "none" }}
              >
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>AR Follow Up Services</h4>
                  <p>
                    Our aggressive follow up on claims ensures that no lost or
                    ignored claims slip through the cracks
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/ar-follow" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="item col-sm-4 col-xs-12 wow fadeInDown"
                // style={{ visibility: "hidden", animationName: "none" }}
              >
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>CREDENTIALING SERVICES</h4>
                  <p>
                    We can perform the entire credentialing process or share the
                    responsibilities with you to apply for your in-network
                    status
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/credentialing/" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Other Solution End --> */}

      <div id="back-top">
        <a href="#home" class="ui-link">
          <i class="fa fa-angle-up"></i>
        </a>
      </div>
    </>
  );
};

export default DenialManagement;
