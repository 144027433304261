import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Credentialing = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i"
          rel="stylesheet"
        />
        <link href="css/bootstrap.css" rel="stylesheet" type="text/css" />
        <link href="css/animate.css" rel="stylesheet" type="text/css" />
        <link href="css/jquery.fancybox.css" rel="stylesheet" type="text/css" />
        <link href="css/owl.carousel.css" rel="stylesheet" type="text/css" />
        <link
          rel="stylesheet"
          href="css/style.css"
          type="text/css"
          media="screen"
        />

        <meta name="robots" content="max-image-preview:large" />
        <title>Credentialing Services </title>
      </Helmet>
      <section class="banner banner-inner wow fadeInDown">
        <img
          src="/images/Credentialing.jpg"
          alt="Provider Enrollment and Credentialing"
        />
      </section>
      {/* <!-- Page Title --> */}
      <section class="section page-title">
        <div class="container">
          <div class="">
            <h1>
              <img
                src="/icons/cr-title-icon.png"
                style={{
                  width: "90px",
                }}
              />
              <br />
              Provider Enrollment and Credentialing
            </h1>
            <div class="col-xs-12 pgtxt">
              <p
                style={{
                  fontSize: "18px",
                }}
              >
                Without being credentialed physicians can't get paid, don’t make
                that mistake. Let the trusted experts at Doc RCM Experts get you
                off to the right start! Our team can handle new enrollments,
                CAQH updates, Medicare re-validation, re-credentialing with
                commercial plans, expirables management and much more. Let us be
                your healthcare credentialing partner and help you maintaining
                your credentialing.
              </p>
              <br />
              <b
                style={{
                  fontSize: "20px",
                }}
              >
                New Credentialing Services:
              </b>
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                <br />
                - Completion of Credentialing Applications with Various Health
                Plans &Follow-Up Until Credentialed
                <br />
                - Review of Contracts
                <br />
                - Assistance Obtaining CAQH ID
                <br />
                - Assistance Obtaining NPI #<br />
              </span>
              <br />
              <b
                style={{
                  fontSize: "20px",
                }}
              >
                Credentialing Maintenance Services:{" "}
              </b>
              <br />
              <span
                style={{
                  fontSize: "18px",
                }}
              >
                - Completion of Re-credentialing Applications with Various
                Health Plans
                <br />
                - Medicare Re-Validation
                <br />
                - CAQH Re-Attestations
                <br />
                - Expirables Management
                <br />
                - Assistance with Denied Claims Due To Credentialing Issues
                <br />
              </span>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Title End--> */}
      <section class="section blue cr-service-box">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-xs-12 service-box">
              <h2>Credentialing tasks we fulfill:</h2>
              <ul style={{
                  fontSize: "18px",
                }}>
                <li>
                  Determine if you are eligible to apply for in-network status
                  with various insurance/HMOs/PPOs
                </li>
                <li>Obtain the necessary paperwork to complete the request</li>
                <li>Apply to get an NPI</li>
                <li>
                  Complete on-line applications, plus CAQH and other
                  credentialing administrations
                </li>
                <li>Quicker Medicare Enrollment by PECOS</li>
                <li>
                  Follow up to track the status of applications and supply
                  missing information as needed
                </li>
                <li>
                  Complete re-credentialing paperwork as requested by
                  insurances/HMOs/PPOs
                </li>
              </ul>
            </div>
            <div class="col-sm-6 col-xs-12 service-box">
              <h2>Credentialing process: </h2>
              <ul style={{
                  fontSize: "18px",
                }}>
                <li>
                  We will ask you to provide several pieces of information
                  required to apply for your credentialing and CAQH application
                </li>
                <li>
                  There are likely to be forms that need information that only
                  you can fill out, such as clinical specialties, etc. We will
                  forward these forms to you for completion
                </li>
                <li>
                  Many forms will require your signature. We will send you these
                  forms to be signed
                </li>
                <li>
                  We will let you know what is needed and the successful
                  completion of this process relies on you supplying the needed
                  documents and information to us in a timely fashion
                </li>
                <li>
                  The credentialing process can take a long time. Please expect
                  that for many insurances, the time from when we first inquire
                  to when you are actually accepted into a panel and can begin
                  seeing patients can be as long as 6 months
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Contact Us --> */}
      <section class="section contact-us wow fadeInDown">
        <div class="container">
          <div class="">
            <div class="col-xs-12">
              <div class="textwidget">
                <div class="cnt-icn">
                  <img src="/images/contactus-icon.png" />
                </div>
                <div class="cnt-txt">
                  <h2>Contact us today</h2>
                  <p>
                    Learn more today about how Doc RCM Experts can be part of
                    your Success
                  </p>
                  <a href="/contactus" class="btn wow slideInRight">
                    REQUEST A QUOTE NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Other Solution End --> */}
      <section class="section as-other-solution">
        <div class="container">
          <div class="">
            <h2>Other Solution</h2>
            <div class="col-xs-12 tms-boxes">
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>MEDICAL BILLING SERVICES</h4>
                  <p>
                    You can rely on the proven combination of our Billing
                    Footsteps Check and expert staff to get 99% of your claims
                    paid
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/medical-billing" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>PRACTICE ANALYTICS SERVICES </h4>
                  <p>
                    We provide you analytic reports that helps you reduce
                    operational cost, increase collection and streamline
                    operation
                  </p>
                  <div class="lmore">
                    <a href="#" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
              <div class="item col-sm-4 col-xs-12 wow fadeInDown">
                <div class="tms-icn">
                  <i class="icn"></i>
                </div>
                <div class="tms-txt">
                  <h4>DENIAL MANAGEMENT SERVICES</h4>
                  <p>
                    Denial Management is not introduced to just resubmitting a
                    claim, our experts also analyze the reasons for denial
                  </p>
                  <div class="lmore">
                    <a href="/billing-services/denial-management" class="btn">
                      LEARN MORE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Other Solution End --> */}
      <div id="back-top">
        <a href="#home" class="ui-link">
          <i class="fa fa-angle-up"></i>
        </a>
      </div>
    </>
  );
};

export default Credentialing;
